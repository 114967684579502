import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import { DialogOverviewDialogComponent } from './../commons/modal/dialog-overview-dialog/dialog-overview-dialog.component';

import { RespValidaItuInterface, ValidaItuInterface, ItuResult } from './../interface/array-itu.interface';
import { ResponseString } from './../interface/response-string';
import { Itu } from '../models/itu';

import { GeneraItuService } from '../services/genera-itu.service';

import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from '../app-config.service';
import { ActivatedRoute } from '@angular/router';
import {  ViewChild } from '@angular/core';
import {NoFacturablesDialogComponent} from './Dialogs/NoFacturablesDialog.component';



@Component({
  selector: 'app-genera-f',
  templateUrl: './genera-f.component.html',
  styleUrls: ['./genera-f.component.css']
})
export class GeneraFComponent implements OnInit {
  // Arreglo para alamcenar mínimo 1 ITU, máximo de 20 ITU´s
  ituArray: Itu[] = [];
  ituMap = new Map();
  display = false;
  billingId: number;
  itu = '';
  amount = 0;
  backgroundMsg = '';
  logoMsg = '';
  colorTexto = '';
  classBtn = '';
  telefono1 = '';
  telefono2 = '';
  emailAtencion = '';
  dialogResponse: boolean;
  //textValue: string;

  ituSendArray: string[] = [];

  respValidaItu: RespValidaItuInterface;

  restart = 0;

  // Form para datos de formulario
  myForm: FormGroup;

  // Bandera para activar el boton aceptar
  activateButton: Boolean = true;

  // Data para resetear el Form
  data: Itu = {
    id: null,
    itu: null,
    status: false,
    msg: null
  };

  // Bandera para controlar el procesamiento de ITU's
  procesar = false;

  continueStep = false;

  // ituValidation: ArrayItu;

  validaItu: ValidaItuInterface;

  ituSeriesArray: string[] = [];
  totalItusResp = 0;

  constructor(private generaItuService: GeneraItuService,
              private router: Router,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private spinnerService: NgxSpinnerService,
              private configService: AppConfigService) {

    this.backgroundMsg = this.configService.getConfig().backgroundMsg;
    this.logoMsg = this.configService.getConfig().logoMsg;
    this.colorTexto = this.configService.getConfig().colorTexto;
    this.classBtn = this.configService.getConfig().classBtn;
    this.telefono1 = this.configService.getConfig().telefono1;
    this.telefono2 = this.configService.getConfig().telefono2;
    this.emailAtencion = this.configService.getConfig().emailAtencion;
    sessionStorage.clear();
    this.myForm = new FormGroup({
      'id': new FormControl(0),
      'itu': new FormControl(
          '',
          [
            Validators.required,
            Validators.minLength(30),
            Validators.maxLength(30),
            Validators.pattern(/20([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])([0-9]{5})([0-9]{3})([0-9]{9})[pP][oO][sS][abAB](0|1|9)/)
          ]
      ),
      'monto': new FormControl(
          '0',
          [
            Validators.required,
            Validators.min(0.01)
          ]
      )
    });
    sessionStorage.clear();
  }

  ituParam: string;
  montoParam: string;

  ngOnInit() {
    this.ituParam = this.route.snapshot.paramMap.get('itu');
    this.montoParam = this.route.snapshot.paramMap.get('monto');
    if (this.ituParam != null && this.montoParam != null) {
      //let encodedITU: string = btoa(this.itu); //Encode
      let encodedITU: string = atob(this.ituParam); //Decode
      console.log(this.ituParam);
      console.log(encodedITU);
      //this.myForm.get('itu').setValue(encodedITU);
      this.itu = encodedITU;

      //let encodedMonto: string = btoa(this.monto); //Enconde
      let encodedMonto: string = atob(this.montoParam); //Deconde
      console.log(this.montoParam);
      console.log(encodedMonto);
      this.myForm.get('monto').setValue(encodedMonto);
    }
  }

  // Agrega Itu al arreglo si la validación del Back-End es true
  validateItu(myFormAux: FormGroup) {
    this.activateButton = false;
    this.spinnerService.show();
    const ituForm = myFormAux.controls['itu'].value.toUpperCase();
    const amountForm = myFormAux.controls['monto'].value;
    this.itu = ituForm;
    this.amount = myFormAux.controls['monto'].value;
    sessionStorage.setItem('amountReinvoice', String(this.amount));
    sessionStorage.setItem('ituReinvoice', this.itu);

    if (sessionStorage.getItem('billingId') === null) {
      this.validaItu = { billingId: 0, auxBillingId: 0, itu: ituForm, amount: amountForm, listItus: this.ituSendArray, xstore: '', series: this.ituSeriesArray, totalItus: 0 };
    } else {
      this.validaItu = { billingId: Number(sessionStorage.getItem('billingId')),
        auxBillingId: Number(sessionStorage.getItem('auxBillingId')),
        itu: ituForm, amount: amountForm, listItus: this.ituSendArray,
        xstore: sessionStorage.getItem('xstore'),
        series: this.ituSeriesArray = [sessionStorage.getItem('ituSerie')],
        totalItus: Number(sessionStorage.getItem('totalItu')) };
    }

    if (this.totalItusResp <= Number(sessionStorage.getItem('totalItu'))) {
      this.generaItuService.validateItu(this.validaItu).subscribe((data: RespValidaItuInterface) => {
        console.log('DATA: ', data);
        this.activateButton = true;
        if (data.status) {

          console.log(data.object.validationMsg);
          if (data.object.validationMsg === 'OK') {
            if (data.ticketMixto) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.width = '700px'; // Ajusta el ancho del diálogo
              dialogConfig.data = {'itu': this.itu};


              const dialogRef = this.dialog.open(NoFacturablesDialogComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  sessionStorage.setItem('billingId', String(data.object.billingId));
                  sessionStorage.setItem('ituSerie', String(data.object.series[0]));
                  sessionStorage.setItem('totalItu', String(data.object.totalItus));
                  sessionStorage.setItem('xstore', String(data.object.xstore));
                  sessionStorage.setItem('auxBillingId', String(data.object.auxBillingId));
                  sessionStorage.setItem('itu', this.myForm.get('itu').value);


                  this.insertItuArray(data.object.itu);
                  this.router.navigate(['/ingresaRfc']);
                } else {
                  this.reiniciarProceso();
                  console.log('Cancelar presionado');
                }
              });
            } else {
              sessionStorage.setItem('billingId', String(data.object.billingId));
              sessionStorage.setItem('ituSerie', String(data.object.series[0]));
              sessionStorage.setItem('totalItu', String(data.object.totalItus));
              sessionStorage.setItem('xstore', String(data.object.xstore));
              sessionStorage.setItem('auxBillingId', String(data.object.auxBillingId));
              sessionStorage.setItem('itu', this.myForm.get('itu').value);


              this.insertItuArray(data.object.itu);
              this.router.navigate(['/ingresaRfc']);
            }


            this.spinnerService.hide();
          } else {

            if (data.object.validationMsg.includes('ITU PREVIAMENTE FACTURADO')) {

              swal({
                text: "Este ticket fue previamente facturado, ¿esta seguro que quiere volver a facturarlo?",
                type: "question",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: this.colorTexto,
                cancelButtonText: "Cancelar",
                confirmButtonText: 'Si, estoy seguro'
              }).then((result) => {
                console.log('entre a la funcion: ' + sessionStorage.getItem('ituReinvoice'));


                if (result.dismiss == null) {
                  if (data.ticketMixto) {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.width = '700px'; // Ajusta el ancho del diálogo
                    dialogConfig.data = {'itu': this.itu};


                    const dialogRef = this.dialog.open(NoFacturablesDialogComponent, dialogConfig);

                    dialogRef.afterClosed().subscribe(res => {
                      if (res) {
                        this.spinnerService.show();
                        this.validateItuReinvoice();
                      } else {
                        this.myForm.reset();
                        return;
                        console.log('Cancelar presionado');
                      }
                    });
                  } else {
                    this.validateItuReinvoice();
                  }


                } else {
                  this.spinnerService.hide();
                }


              });

            } else {
              this.myForm.reset();
              this.itu = '';
              swal('Error!', data.object.validationMsg, 'error');
            }


            this.spinnerService.hide();
          }
        } else {
          //this.myForm.reset();
          this.spinnerService.hide();
          swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
        }
      }, err => {
        this.spinnerService.hide();
        this.myForm.reset();
        this.itu = '';
        this.amount =0;
        swal('Error!', 'Error al conectar con el Servidor. Se va reinciar la página', 'error');

        setTimeout(() => {
          window.location.reload();
        }, 3000);

      });
    } else {
      this.spinnerService.hide();
      swal('Error!', `El límite de ITU´s es ${Number(sessionStorage.getItem('totalItu'))}`, 'warning');
    }

  }

// Inserta ITU´s al arreglo temporal
  insertItuArray(itu: string) {
    console.log('Inserto en array');
    this.spinnerService.hide();
    const selectedItu: Itu = new Itu();
    selectedItu.itu = itu;
    if (this.restart === 0) {
      selectedItu.id = this.ituArray.length + 1;
    } else {
      selectedItu.id = this.ituArray.length;
    }
    selectedItu.status = true;
    this.maxAddArrayItu(selectedItu);

  }

// Determina la cantidad de ITU´s a almacenar al array
  maxAddArrayItu(ituObject: Itu) {
    const totItu = Number(sessionStorage.getItem('totalItu'));
    if (this.restart !== 1) {
      this.maxLength(totItu, ituObject);
    } else {
      this.maxLength(totItu + 1, ituObject);
    }
  }

  // Almacena los ITU´s al arreglo
  maxLength(max: number, ituObject: Itu) {
    if (this.ituArray.length < max) {
      this.ituArray.push(ituObject);
      this.ituSendArray.push(ituObject.itu);
      swal('', 'Validación exitosa', 'success');
    } else {
      swal('', `El límite de ITU´s es ${Number(sessionStorage.getItem('totalItu'))}`, 'warning');
    }
  }

  // Valida (Back-End) el arreglo de ITU's
  procesarItus() {
    swal('', 'ITU´s guardados exitosamente', 'success');
    this.router.navigate(['/ingresaRfc']);
  }

  // Reinicia el proceso de Genera Factura
  reiniciarProceso() {
    swal({
      text: "¿Realmente deseas cancelar el proceso de generación de factura?",
      type: "question",
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: this.colorTexto,
      cancelButtonText: "No",
      confirmButtonText: 'Si'
    }).then((result) => {
      console.log('entre a la funcion: ' + sessionStorage.getItem('ituReinvoice'));

      if (result.dismiss == null) {
        console.log(this.ituArray);
        console.log('billingId = ' + Number(sessionStorage.getItem('billingId')));

        this.validaItu = {
          billingId: Number(sessionStorage.getItem('billingId')),
          auxBillingId: Number(sessionStorage.getItem('auxBillingId')),
          itu: '', amount: 0, listItus: this.ituSendArray,
          xstore: sessionStorage.getItem('xstore'),
          series: this.ituSeriesArray = [sessionStorage.getItem('ituSerie')],
          totalItus: Number(sessionStorage.getItem('totalItu'))
        };

        this.generaItuService.releaseItus(this.validaItu).subscribe((data: object) => {
          console.log('DATA: ', data);
          if (data != null) {
            if (data['object'] === 'OK') {
              this.router.navigate(['/home']);
            } else {
              swal('Error', 'Error al conectar con el Servidor', 'error');
            }
          } else {
            swal('Error', 'Error al conectar con el Servidor', 'error');
          }

        }, err => {
          console.error(err);
          this.spinnerService.hide();
          swal('Error', 'Error al conectar con el Servidor', 'error');
        });

        this.myForm.reset(this.data);
        this.ituArray = [];
        this.ituSendArray = [];
        this.ituSeriesArray = [];
        this.restart = 0;
        this.totalItusResp = 0;
        sessionStorage.clear();
      }
    });
  }

  removeItu(ituDeleted: string){

    const ituDeleteArray = [ituDeleted];

    this.validaItu = { billingId: Number(sessionStorage.getItem('billingId')),
      auxBillingId: Number(sessionStorage.getItem('auxBillingId')),
      itu: '', amount: 0, listItus: ituDeleteArray,
      xstore: sessionStorage.getItem('xstore'),
      series: this.ituSeriesArray = [sessionStorage.getItem('ituSerie')],
      totalItus: Number(sessionStorage.getItem('totalItu')) };

    this.generaItuService.releaseItus(this.validaItu).subscribe((data: object) => {
      console.log('DATA: ', data);
      if(data != null){
        if(data['object'] === 'OK'){
          const ituPos = this.ituSendArray.indexOf(ituDeleted);
          this.ituSendArray.splice(ituPos, 1);

          this.ituArray = [];

          if(this.ituSendArray.length > 0){
            for(let index = 0; index < this.ituSendArray.length; index++){
              const ituSelect: Itu = new Itu();
              ituSelect.id = index + 1;
              ituSelect.itu = this.ituSendArray[index];
              this.ituArray.push(ituSelect);
            }
          }else{
            this.myForm.reset(this.data);
            this.ituArray =  [];
            this.ituSendArray = [];
            this.ituSeriesArray = [];
            this.restart = 0;
            this.totalItusResp = 0;
            sessionStorage.clear();
          }

          swal('', "Itu " + ituDeleted + 'eliminado correctamente', 'success');
        } else {
          swal('Error', 'Error al conectar con el Servidor', 'error');
        }
      } else {
        swal('Error', 'Error al conectar con el Servidor', 'error');
      }

    }, err => {
      console.error(err);
      this.spinnerService.hide();
      swal('Error', 'Error al conectar con el Servidor', 'error');
    });

  }

  validateItuReinvoice() {

    if (sessionStorage.getItem('billingId') === null) {
      this.validaItu = { billingId: 0, auxBillingId: 0, itu: sessionStorage.getItem('ituReinvoice'), amount: Number(sessionStorage.getItem('amountReinvoice')), listItus: [], xstore: '', series: [], totalItus: 0 };
    }

    this.generaItuService.validateItuReinvoice(this.validaItu).subscribe((data: RespValidaItuInterface) => {
      console.log('DATA: ', data);
      this.activateButton = true;
      if (data.status) {
        if (data.object.validationMsg === 'OK') {
          if (data.object.billingId > 0) {
            // console.log('billingId ' + data.object.billingId);
            sessionStorage.setItem('billingId', String(data.object.billingId));
          }
          this.ituSendArray = data.object.listItus;

          this.display = true;
          this.spinnerService.hide();
        } else {
          this.myForm.reset();
          swal('Error!', data.object.validationMsg, 'error');
          this.spinnerService.hide();
        }
      } else {
        this.myForm.reset();
        this.spinnerService.hide();
        swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
      }
    }, err => {
      console.error(err);
      this.myForm.reset();
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
  }

  accept() {
    this.spinnerService.show();
    sessionStorage.setItem('itu', String(this.itu));
    this.spinnerService.hide();
    this.router.navigate(['/reinvoiceEditData']);
  }

  cancel() {
    this.spinnerService.show();
    this.billingId = Number(sessionStorage.getItem('billingId'));
    this.validaItu = {
      billingId: this.billingId,
      auxBillingId: 0,
      itu: this.itu,
      amount: this.amount,
      listItus: this.ituSendArray,
      xstore: '',
      series: this.ituSeriesArray,
      totalItus: 1
    };
    this.generaItuService.cancelReinvoice(this.validaItu).subscribe((data: ResponseString) => {
      console.log('DATA: ', data);
      if (data !== null) {
        if (data.status) {
          if (data.object !== null) {
            if (data.object === 'OK') {
              this.display = false;
              swal('', 'Solicitud de re.factura del itu: ' + this.itu + ' iniciada.', 'success');
              this.spinnerService.hide();
            } else {
              swal('Error!', data.object, 'error');
            }
          } else {
            swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
          }
        } else {
          this.spinnerService.hide();
          swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
        }
      }
    }, err => {
      console.error(err);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
    console.log('Cancel');
  }

  selectAll($event: MouseEvent) {
    const input = event.target as HTMLInputElement;
    input.select();
  }
}
