import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PoliticasComponent } from './assets/html/politicasHuawei.component';

import { GeneraFComponent } from './genera-f/genera-f.component';
import { IngresaRfcComponent } from './genera-f/ingresa-rfc/ingresa-rfc.component';
import { FormInvoiceComponent } from './genera-f/form-invoice/form-invoice.component';

import { PorRfcComponent } from './consulta-f/por-rfc/por-rfc.component';
import { PorItuComponent } from './consulta-f/por-itu/por-itu.component';

import { GuiaFComponent } from './guia-f/guia-f.component';
import { TableItuComponent } from './consulta-f/por-itu/table-itu/table-itu.component';
import { TableRfcComponent } from './consulta-f/por-rfc/table-rfc/table-rfc.component';
import { IngresaDatosComponent } from './refacturacion/ingresa-datos/ingresa-datos.component';

import { ReinvoiceInputItuComponent } from './reinvoice/reinvoice-input-itu/reinvoice-input-itu.component';
import { ReinvoiceEditDataComponent } from './reinvoice/reinvoice-edit-data/reinvoice-edit-data.component';

import { GuideReinvoiceComponent } from './guides/guide-reinvoice/guide-reinvoice.component';
import { GuideInvoiceComponent } from './guides/guide-invoice/guide-invoice.component';
import { GuideConsultComponent } from './guides/guide-consult/guide-consult.component';
import { FaqComponent } from './guides/faq/faq.component';

import { environment } from '../environments/environment';

const routes: Routes = [
  // ***********************Módulo de Genera Factura*********************************
  // ********************************************************************************
  {
    path: 'generaF',
    component: GeneraFComponent
  },
  {
    path: 'generaF/:itu/:monto',
    component: GeneraFComponent
  },
  {
    path: 'refacturacion',
    component: ReinvoiceInputItuComponent
  },
  {
    path: 'reinvoiceEditData',
    component: ReinvoiceEditDataComponent
  },
  {
    path: 'ingresaRfc',
    component: IngresaRfcComponent
  },
  {
    path: 'dataInvoice',
    component: FormInvoiceComponent
  },


  // **********************Módulo de Consulta  FActura******************************
  // ********************************************************************************
  {
    path: 'cItuF',
    component: PorItuComponent,
  },
  {
    path: 'cRfcF',
    canActivate: ['isInternalPortal'],

    component: PorRfcComponent,
  },
  {
    path: 'tableItu',
    canActivate: ['isInternalPortal'],
    component: TableItuComponent
  },
  {
    path: 'tableRfc',
    canActivate: ['isInternalPortal'],
    component: TableRfcComponent
  },

  // ***********************Módulo de Guía Rápida*********************************
  // ********************************************************************************
  {
    path: 'itusTypes',
    component: GuiaFComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'guideReinvoice',
    component: GuideReinvoiceComponent,
  },
  {
    path: 'guideInvoice',
    component: GuideInvoiceComponent,
  },
  
  {
    path: 'guideConsult',
    component: GuideConsultComponent,
  },
  // Home
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // Footer politicas
  {
    path: 'politicas',
    component: PoliticasComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
      {
        useHash: true
      }
    )],
  exports: [RouterModule],
  providers: [
    {
      provide: 'isInternalPortal',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => environment.internal
    }
  ]
})
export class AppRoutingModule { }
