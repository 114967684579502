import { ConsultInterface } from './../../interface/consult.interface';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConsultService } from './../../services/consult.service';
import { SelectTipo } from './../../interface/selectTipo.interface';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { AppConfigService } from '../../app-config.service';

@Component({
  selector: 'app-por-itu',
  templateUrl: './por-itu.component.html',
  styleUrls: ['./por-itu.component.css']
})
export class PorItuComponent implements OnInit {
  myForm: FormGroup;
  showMessage = false;
  senData: ConsultInterface;

  msg = '';
  colorTexto = '';
  amount = 0;
  classBtn = '';
  classBackground = '';
  ituLength = '';
  dataRestForm = {
    itu: '',
    mail: ''
  };

  loading = false;

  constructor(private router: Router,
              private consultService: ConsultService,
              private spinnerService: NgxSpinnerService,
              private configService: AppConfigService) {
    this.colorTexto = this.configService.getConfig().colorTexto;
    this.classBtn = this.configService.getConfig().classBtn;
    this.classBackground = this.configService.getConfig().classBackground;
    sessionStorage.clear();
    this.myForm = new FormGroup({
      'itu': new FormControl(
              '' ,
              [
                Validators.required,
                Validators.minLength(30),
                Validators.maxLength(30),
                Validators.pattern(/20([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])([0-9]{5})([0-9]{3})([0-9]{9})([a-zA-z]{4})[1,9]/)
              ]
            ),
      'monto': new FormControl(
              '0',
               [
                 Validators.required,
                 Validators.min(0.1)
               ]
            )
    });
  }

  ngOnInit() {
  }

  okITU(myForm: FormGroup) {
    console.log("paso1");
    sessionStorage.setItem('consultItu', this.myForm.controls['itu'].value);
    console.log("paso2");
    sessionStorage.setItem('consultMonto', this.myForm.controls['monto'].value);
    console.log("paso3");
    this.router.navigate(['/tableItu']);
    console.log("paso4");
  }

  sendData() {
    this.spinnerService.show();
    this.loading = true;
    this.senData = {
      dateEnd: '',
      dateInit: '',
      email: this.myForm.controls['mail'].value,
      itu: this.myForm.controls['itu'].value,
      rfc: '',
      amount: this.myForm.controls['monto'].value
    };

    this.consultService.consultByITU(this.senData).subscribe( (data: any) => {
      console.log('DATA: ', data);
      if (!data.status) {
        this.loading = false;
        this.spinnerService.hide();
        swal('Error!', data.msg, 'error');
      } else {
        this.spinnerService.hide();
        this.loading = false;
        this.msg = data.object;
        this.showMessage = true;
      }
    },
    error => {
      this.loading = false;
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });

    this.myForm.reset(this.dataRestForm);
  }

  newConsult() {
    this.showMessage = false;
    this.myForm.reset();
  }
  selectAll($event: MouseEvent) {
    const input = event.target as HTMLInputElement;
    input.select();
  }

}
