import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { AppConfigService } from 'src/app/app-config.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faqFilePdf: SafeResourceUrl;
  pdfSrc: any;
  loading = false;

  id: number;

  constructor(private activateRoute: ActivatedRoute, private congifServer: AppConfigService, sanitizer: DomSanitizer) {
    this.faqFilePdf = sanitizer.bypassSecurityTrustResourceUrl(this.congifServer.getConfig().faqPdf);
  }


  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      if (params['id'] != null) {
        this.id = params['id'];
        console.log('Valor del ID: ', this.id);
      }
    });

  }

}
