import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {GeneraItuService} from '../../services/genera-itu.service';
import {Items} from '../../models/items';
import {error} from 'protractor';
import {AppConfigService} from '../../app-config.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'no-facturables-dialog',
    templateUrl: './NoFacturablesDialog.component.html',
    styleUrls: ['./NoFacturablesDialog.component.css']
})
export class NoFacturablesDialogComponent implements OnInit {
    showDetails = false;
    displayedColumns: string[] = ['SKU', 'Descripcion'];
    /*details = [
        { field1: '100178156', field2: 'COLOCAC Y CONFIG LUZ WIFI/BLUE.' }
    ];*/
    items: Items[];
    itu: string;
    public fullCompanyName: string;

    constructor(
        public dialogRef: MatDialogRef<NoFacturablesDialogComponent>,
        public ituService: GeneraItuService,
        private configService: AppConfigService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
       this.itu = this.data.itu;
       this.fullCompanyName = configService.getConfig().fullCompanyName;
    }
    ngOnInit(): void {
        console.log('Abriendo modal ');
        this.getItems();

    }
    onClose(): void {
        this.dialogRef.close();
    }
    onContinue(): void {
        this.dialogRef.close(true);
    }

    onViewDetails(): void {
        this.showDetails = true;
    }

    getItems(): void {
        console.log('mesanje');
        const obj = {'itu': this.itu};
        this.ituService.getItemsNoFacturables(obj).subscribe( resp =>{
            this.items = resp;
        });
    }
}
