import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { PeriodicElement } from './../../../interface/periodic-element';
import { CsvFile, XmlFile, PdfFile } from '../../../interface/periodic-element';
import { ConsultInterface } from '../../../interface/consult.interface';
import { ConsultService } from '../../../services/consult.service';
import { DomSanitizer } from '@angular/platform-browser';
import {saveAs as importedSaveAs} from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { DialogOverviewDialogComponent } from '../../../commons/modal/dialog-overview-dialog/dialog-overview-dialog.component';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Router } from '@angular/router';



@Component({
  selector: 'app-table-itu',
  templateUrl: './table-itu.component.html',
  styleUrls: ['./table-itu.component.css']
})
export class TableItuComponent implements OnInit {

  // Arreglo que almacena los datos consultados por el RFC
  dataArray: PeriodicElement[] = [];
  // Arreglo para guardar datos al descargar alrchivo .csv
  arraySCV: CsvFile[] = [];

  // Cabeceros de Tabla a mostrar
  displayedColumns: string[] = ['rfcReceiver', 'voucher', 'itu', 'emissionDate', 'actions', 'actionsPdf', 'actions2'];
  // Almacen ce datos
  dataSource = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  email = '';
  name = '';

  fechaIni: NgbDateStruct;
  minDate1 = new Date(2010, 0, 1);
  maxDate1 = new Date();

  fechaFin: NgbDateStruct;
  minDate2 = new Date(2010, 0, 1);
  maxDate2 = new Date();

  senData: ConsultInterface;

  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    // title: 'Your Holiday List :',
    useBom: false,
    noDownload: false,
    headers: ['RFC', 'VOUCHER', 'ITU', 'FECHA_EMISION']
  };

  itu = '';
  mail = '';
  monto = '';
  tipo = '';

  constructor(public dialog: MatDialog,
              private datePipe: DatePipe,
              private consultService: ConsultService,
              private domSanitizer: DomSanitizer,
              private spinnerService: NgxSpinnerService,
              private router: Router) {

      this.getDataByItu();
  }

  ngOnInit() {
  }

  getDataByItu() {
    this.spinnerService.show();
    if (sessionStorage.getItem('consultItu') !== null) {
      this.itu = sessionStorage.getItem('consultItu');
      this.mail = sessionStorage.getItem('consultMail');
      this.monto = sessionStorage.getItem('consultMonto');
      this.senData = {
        dateEnd: '',
        dateInit: '',
        email: this.mail,
        itu: this.itu,
        rfc: '',
        amount: Number(this.monto)
      };
      console.log('senData: ', this.senData);
      this.consultService.consultByITU(this.senData).subscribe((data: any) => {
        console.log('DATA: ', data);
        this.dataArray = data.object;
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
        this.dataSource.paginator = this.paginator;
        this.spinnerService.hide();
      },
      error => {
        console.log('Error: ', error);
        this.spinnerService.hide();
        swal('Error!', 'Error al conectar con el Servidor', 'error');
      });
    } else {
      this.spinnerService.hide();
      swal('Error!', 'No se pudo obtener el ITU', 'error');
      this.router.navigate(['/cRfcF']);
    }
  }

  myFilterX = (d: Date): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 6;
  }

  getDateIni(event: Date) {
    if (event !== null) {
      this.minDate2 = event;
    }
  }

  sendDate() {
    this.spinnerService.show();
    const sendFI = this.datePipe.transform(this.fechaIni, 'dd/MM/yyyy');
    const sendFF = this.datePipe.transform(this.fechaFin, 'dd/MM/yyyy');
    console.log('Fecha inicial: ', sendFI);
    console.log('Fecha final: ', sendFF);
    console.log('RFC-senDate: ', sessionStorage.getItem('consultRfc'));
    this.senData = {
      dateInit: sendFI,
      dateEnd: sendFF,
      email: '',
      itu: '',
      rfc: sessionStorage.getItem('consultRfc'),
      amount: 0
    };
    this.consultService.consultByRFC(this.senData).subscribe((data: any) => {
      console.log('DATA: ', data);
      if (!data.status) {
        this.spinnerService.hide();
        swal('Error!', data.msg, 'error');
      } else {
        if (data.object.length !== 0) {
          this.dataArray = data.object;
          this.dataArray = this.addIndex(this.dataArray);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.dataArray);
          this.dataSource.paginator = this.paginator;
          this.spinnerService.hide();
        } else {
          this.spinnerService.hide();
          swal('Error!', 'No hay datos para mostrar', 'error');
        }
      }
    },
    error => {
      console.log('Error: ', error);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  addIndex(arr): PeriodicElement[] {
    for (let i = 0; i < arr.length; i++) {
      arr[i].position = i + 1;
    }
    return arr;
  }

  downloadCSV() {
    console.log('Descarga CSV... ', this.dataArray);
    this.copyArray();
    console.log('Array para el CSV: ', this.arraySCV);
    // tslint:disable-next-line: no-unused-expression
    new AngularCsv(this.arraySCV, 'ReporteFacturas', this.csvOptions);
  }

  copyArray() {
    for (let i = 0; i < this.dataArray.length; i++) {
      const obj: CsvFile = {
        rfcReceiver: this.dataArray[i].rfcReceiver,
        voucher: this.dataArray[i].voucher,
        itu: this.dataArray[i].itu,
        emissionDate: this.dataArray[i].emissionDate
      };
      this.arraySCV.push(obj);
    }
  }

  downloadXML( element ) {
    this.spinnerService.show();
    console.log('Vaucher: ', element.voucher);
    this.consultService.getXML(element.voucher).subscribe((data: XmlFile) => {
      console.log('DATA: ', data);
      if (!data.status) {
        this.spinnerService.hide();
        swal('', data.msg, 'error');
      } else {
        // const datXml = '<?xml version="1.0" encoding="UTF-7" ?><album> <autor>SABINA Y CIA Nos sobran los motivos</autor> <titulo>Joaquín Sabina</titulo> 	<formato>MP3</formato><localizacion>Varios CD5 </localizacion></album>';
        const datXml = data.object;
        const blob = new Blob([datXml], { type: 'application/x-www-form-urlencoded' });
        // this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        this.spinnerService.hide();
        importedSaveAs(blob, 'Factura_Electronica.xml');
        console.log('Aquí se trata al Objeto!!!');
      }
    },
    err => {
      console.log('ERROR-XML: ', err);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
  }

  downloadPDF( element ) {
    this.spinnerService.show();
    var ituRequest = element.itu;
    console.log(ituRequest.length);
    if(ituRequest.length == 30) {
      ituRequest=element.itu.substring(0,29);
    }
    console.log('Vaucher: ', ituRequest);
    this.consultService.getPDF(ituRequest).subscribe((data: PdfFile) => {
      console.log('DATA: ', data);
      if (!data.status) {
        this.spinnerService.hide();
        swal('', data.msg, 'error');
      } else {
        // const datXml = '<?xml version="1.0" encoding="UTF-7" ?><album> <autor>SABINA Y CIA Nos sobran los motivos</autor> <titulo>Joaquín Sabina</titulo> 	<formato>MP3</formato><localizacion>Varios CD5 </localizacion></album>';
        const datPdf = data.object;

        const linkSource = 'data:application/pdf;base64,' + datPdf;
        const downloadLink = document.createElement("a");
        const fileName = "Factura_Electronica.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.spinnerService.hide();
        console.log('Aquí se trata al Objeto!!!');
      }
    },
    err => {
      console.log('ERROR-PDF: ', err);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
  }

  // Ingreso de E-mail para envio de FActura
  openDialog(valItu): void {
    this.email = '';
    this.name = '';
    const dialogRef = this.dialog.open(DialogOverviewDialogComponent, {
      width: '250px',
      data: {name: this.name, val: this.email}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.email = result.value;

        this.senData = {
          dateInit: '',
          dateEnd: '',
          email: this.email,
          itu: valItu.itu,
          // rfc: valItu.rfcReceiver
          rfc: '',
          amount: valItu.amount
        };
        this.spinnerService.show();
        this.consultService.sendEmail(this.senData).subscribe( (data: XmlFile) => {
          console.log('DATA: ', data);
          if (!data.status) {
            this.spinnerService.hide();
            swal('Error!', `${data.msg}`, 'error');
          } else {
            this.spinnerService.hide();
            swal('Ok', `${data.object} : ${this.email}`, 'success');
          }
          // swal('Ok', `La Factura fue enviada al siguiente correo: ${this.email}`, 'success');
        },
        error => {
          console.log('ERROR: ', error);
          this.spinnerService.hide();
          swal('Error!', 'Error al conectar con el Servidor', 'error');
        });
      }
    });
    this.spinnerService.hide();
  }

}
