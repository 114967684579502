import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sheet-overview',
  templateUrl: './sheet-overview.component.html',
  styleUrls: ['./sheet-overview.component.css']
})
export class SheetOverviewComponent implements OnInit {

  url = '/tableRfc';

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private _bootomSheetRef: MatBottomSheetRef<SheetOverviewComponent>,
              private router: Router) {
                console.log('_bootomSheetRef: ', _bootomSheetRef);
              }

  ngOnInit() {}

  openLink( event: MouseEvent): void {
    this._bootomSheetRef.afterDismissed().subscribe( () => {
    this.router.navigate(['/tableRfc']);
  });
  this._bootomSheetRef.backdropClick().subscribe( () => {
    this.router.navigate(['/tableRfc']);
  });
  this._bootomSheetRef.dismiss();
  event.preventDefault();
  }

}
