import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  imgHome = '';
  now: number;
  constructor(private _reder: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private congifServer: AppConfigService
    ) {
      this.imgHome = this.congifServer.getConfig().imgHome;
    setInterval(() => {
          this.now = Date.now();
        }, 1);
  }

  ngOnInit() {
    let scriptOld = this._document.getElementById('script1');
    if(scriptOld != null){
      scriptOld.remove();
      scriptOld = this._document.getElementById('script2');
      scriptOld.remove();
    }

    let tokenProds = this.congifServer.getConfig().tokenProds;
    let categoryProds = this.congifServer.getConfig().categoryProds;
    let companyName = this.congifServer.getConfig().companyName;

    let script = this._reder.createElement('script');
    script.type = 'text/javascript';
    script.id = 'script1';
    script.text =  `
      var ScarabQueue = ScarabQueue || [];
      (function(id) {
        if (document.getElementById(id)) return;
        var js = document.createElement('script'); js.id = id;
        js.src = '//cdn.scarabresearch.com/js/` + tokenProds + `/scarab-v2.js';
        var fs = document.getElementsByTagName('script')[0];
        fs.parentNode.insertBefore(js, fs);
      })('scarab-js-api');
    `;

    let script2 = this._reder.createElement('script');
    script2.type = 'text/javascript';
    script2.id = 'script2';
    if(companyName === 'OD') {
      script2.text =  `
      var widthW = window.screen.width;
      widthW = widthW - (widthW / 10);
      if(widthW >= 300){
          if(widthW >= 900){
              widthW = widthW - 250;
          }
          var items = Math.floor(widthW / 304);
          ScarabQueue.push(['category', '` + categoryProds + `']);
          for (var i = 1; i < 5; i++) {
              ScarabQueue.push(['recommend', {
                  logic: 'DEPARTMENT_' + i,
                  limit: items,
                  containerId: 'dept' + i,
                  templateId: 'simple-tmpl',
                  success: function(SC, render) {
                      if (SC.topicLocalized) {
                          SC.title = SC.topicLocalized.replace(/Root Catalog>/g, '');
                          render(SC);
                      }
                  }
              }]);    
          }
          ScarabQueue.push(['go']);
        }
      `;
    } else {
      script2.text =  `
      console.log('script2');
      var widthW = window.screen.width;
      console.log(widthW);
      widthW = widthW - (widthW / 10);
      if(widthW >= 300){
          if(widthW >= 900){
              widthW = widthW - 250;
          }
          var items = Math.floor(widthW / 304);
          ScarabQueue.push(['searchTerm', '` + categoryProds + `']);
          
              ScarabQueue.push(['recommend', {
                  logic: 'SEARCH',
                  limit: 6,
                  containerId: 'search-recs',
                  templateId: 'search-template'
              }]);    
          
          ScarabQueue.push(['go']);
        }
      `;
    }

    this._reder.appendChild(this._document.body, script);
    this._reder.appendChild(this._document.body, script2);
  }

}
