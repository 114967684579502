import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Router } from '@angular/router';

@Component({
  selector: 'app-por-rfc',
  templateUrl: './por-rfc.component.html',
  styleUrls: ['./por-rfc.component.css']
})
export class PorRfcComponent implements OnInit, AfterViewInit {

  myForm: FormGroup;
  constructor(private router: Router) {
    sessionStorage.clear();
    this.myForm = new FormGroup({
      'rfc': new FormControl('',
            [
              Validators.required,
              Validators.minLength(12),
              Validators.maxLength(13),
              Validators.pattern('[A-Za-zñÑ\x26]{3,4}[0-9]{2}[01][0-9][0-3][0-9][!-}ñÑ]{3}')
            ])
    });
   }

  ngOnInit() {}

  ngAfterViewInit() {
  }

  okRfc(myForm: FormGroup) {
    sessionStorage.setItem('consultRfc', this.myForm.controls['rfc'].value);
    this.router.navigate(['/tableRfc']);
  }

}
