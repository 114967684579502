import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RespValidaItuInterface, ValidaItuInterface } from './../../interface/array-itu.interface';
import { ResponseString } from './../../interface/response-string';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from '../../app-config.service';
import { GeneraItuService } from '../../services/genera-itu.service';

@Component({
  selector: 'app-reinvoice-input-itu',
  templateUrl: './reinvoice-input-itu.component.html',
  styleUrls: ['./reinvoice-input-itu.component.css']
})
export class ReinvoiceInputItuComponent implements OnInit {

  myForm: FormGroup;
  validaItu: ValidaItuInterface;
  ituSendArray: string[] = [];
  ituSeriesArray: string[] = [];
  display = false;
  itu = '';
  amount = 0;
  billingId: number;
  backgroundMsg = '';
  logoMsg = '';
  colorTexto = '';
  classBtn = '';
  telefono1 = '';
  telefono2 = '';
  emailAtencion = '';

  constructor(private spinnerService: NgxSpinnerService,
    private router: Router,
    private generaItuService: GeneraItuService,
    private configService: AppConfigService) {

      this.backgroundMsg = this.configService.getConfig().backgroundMsg;
      this.logoMsg = this.configService.getConfig().logoMsg;
      this.colorTexto = this.configService.getConfig().colorTexto;
      this.classBtn = this.configService.getConfig().classBtn;
      this.telefono1 = this.configService.getConfig().telefono1;
      this.telefono2 = this.configService.getConfig().telefono2;
      this.emailAtencion = this.configService.getConfig().emailAtencion;
    sessionStorage.clear();
    this.myForm = new FormGroup({
      'itu': new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(30),
          Validators.maxLength(30),
          Validators.pattern(/20([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])([0-9]{5})([0-9]{3})([0-9]{9})([a-zA-z]{4})1/)
        ]
      ),
      'monto': new FormControl(
              '0',
              [
                Validators.required,
                Validators.min(0.1)
              ]
            )
    });

  }

  ngOnInit() {
  }


  // valida ITU a refacturar
  validateItu(myFormAux: FormGroup) {
    console.log('Entrando a validar');
    this.spinnerService.show();
    const ituForm = myFormAux.controls['itu'].value;
    this.itu = ituForm;
    this.amount = myFormAux.controls['monto'].value;

    if (sessionStorage.getItem('billingId') === null) {
      this.validaItu = { billingId: 0, auxBillingId: 0, itu: ituForm, amount: this.amount, listItus: this.ituSendArray, xstore: '', series: this.ituSeriesArray, totalItus: 0 };
    }

    this.generaItuService.validateItuReinvoice(this.validaItu).subscribe((data: RespValidaItuInterface) => {
      console.log('DATA: ', data);
      this.myForm.reset();   // limpia el formulario
      if (data.status) {
        if (data.object.validationMsg === 'OK') {
          if (data.object.billingId > 0) {
            // console.log('billingId ' + data.object.billingId);
            sessionStorage.setItem('billingId', String(data.object.billingId));
          }
          this.ituSendArray = data.object.listItus;

          this.display = true;
          this.spinnerService.hide();
        } else {
          swal('Error!', data.object.validationMsg, 'error');
          this.spinnerService.hide();
        }
      } else {
        this.spinnerService.hide();
        swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
      }
    }, err => {
      console.error(err);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });

  }


  accept() {
    this.spinnerService.show();
    sessionStorage.setItem('itu', String(this.itu));
    this.spinnerService.hide();
    this.router.navigate(['/reinvoiceEditData']);
  }

  cancel() {
    this.spinnerService.show();
    this.billingId = Number(sessionStorage.getItem('billingId'));
    this.validaItu = { billingId: this.billingId, auxBillingId: 0, itu: this.itu, amount: this.amount, listItus: this.ituSendArray, xstore: '', series: this.ituSeriesArray, totalItus: 1 };
    this.generaItuService.cancelReinvoice(this.validaItu).subscribe((data: ResponseString) => {
      console.log('DATA: ', data);
      if(data !== null){
        if (data.status) {
          if(data.object !== null){
              if(data.object === 'OK'){
              this.display = false;
              swal('', 'Solicitud de re.factura del itu: ' + this.itu + ' iniciada.', 'success');
              this.spinnerService.hide();
            }else{
              swal('Error!', data.object, 'error');
            }
          }else{
            swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
          }
        } else {
          this.spinnerService.hide();
          swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
        }
      }
    }, err => {
      console.error(err);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
    console.log('Cancel');
  }

}
