import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoSelectService } from 'src/app/services/tipoSelect.service';
import { SelectGeneric, SelectTipo } from './../../interface/selectTipo.interface';

@Component({
  selector: 'app-ingresa-datos',
  templateUrl: './ingresa-datos.component.html',
  styleUrls: ['./ingresa-datos.component.css']
})
export class IngresaDatosComponent implements OnInit {

  myForm: FormGroup;
  arrPerson: SelectTipo[] = [];
  arrTipoUso: SelectTipo[] = [];

  constructor(private tipoSelect: TipoSelectService,
    private fb: FormBuilder,
    private router: Router,
    private spinnerService: NgxSpinnerService) {
    this.createForm();
    this.tipoSelect.getTipoPersona().subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      if (data !== null) {
        this.arrPerson = data.object;
      }
    });
  }

  ngOnInit() { }


  createForm() {
    this.myForm = this.fb.group({
      'rfc': ['',
        [
          Validators.maxLength(13)
          && Validators.pattern('[A-Za-z]{3,4}[0-9]{2}[01][0-9][0-3][0-9][!-}ñÑ]{3}'),
          Validators.required
        ]
      ],
      'rs': ['', Validators.required],
      'tipo_persona': ['', Validators.required],
      'tipo_uso': ['', Validators.required]
    });
  }

  getTipoUso(tPersona: FormGroup) {
    if (tPersona.controls['tipo_persona'].value === '0') {
      this.tipoSelect.getTipoUsoF().subscribe((data1: SelectGeneric) => {
        console.log('DATA: ', data1);
        this.arrTipoUso = data1.object;
      });
    } else {
      this.tipoSelect.getTipoUsoM().subscribe((data2: SelectGeneric) => {
        console.log('DATA: ', data2);
        this.arrTipoUso = data2.object;
      });
    }
  }


  sendData(data: FormGroup) {
    console.log('Mandando datos...');
    this.spinnerService.show();
    sessionStorage.setItem('rfc', this.myForm.controls.rfc.value.toLocaleUpperCase());
    sessionStorage.setItem('type_person', this.myForm.controls.tipo_persona.value);
    sessionStorage.setItem('uso_cfdi', this.myForm.controls.tipo_uso.value);
    this.spinnerService.hide();
    // this.router.navigate(['/dataInvoice']);
  }

}
