import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-guide-reinvoice',
  templateUrl: './guide-reinvoice.component.html',
  styleUrls: ['./guide-reinvoice.component.css']
})
export class GuideReinvoiceComponent implements OnInit {
  urlRefact: SafeResourceUrl;

  constructor(private congifServer: AppConfigService, sanitizer: DomSanitizer) {
    this.urlRefact = sanitizer.bypassSecurityTrustResourceUrl(this.congifServer.getConfig().urlVideoRefact);
  }

  ngOnInit() { }

}
