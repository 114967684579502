import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-guide-consult',
  templateUrl: './guide-consult.component.html',
  styleUrls: ['./guide-consult.component.css']
})
export class GuideConsultComponent implements OnInit {

  urlConsult: SafeResourceUrl;

  constructor(private congifServer: AppConfigService, sanitizer: DomSanitizer) {
    this.urlConsult = sanitizer.bypassSecurityTrustResourceUrl(this.congifServer.getConfig().urlVideoConsult);
  }
  ngOnInit() {
  }

}
