import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-guide-invoice',
  templateUrl: './guide-invoice.component.html',
  styleUrls: ['./guide-invoice.component.css']
})
export class GuideInvoiceComponent implements OnInit {

  urlGenera: SafeResourceUrl;

  constructor(private congifServer: AppConfigService, sanitizer: DomSanitizer) {
    this.urlGenera = sanitizer.bypassSecurityTrustResourceUrl(this.congifServer.getConfig().urlVideoGenera);
  }

  ngOnInit() {
  }

}
