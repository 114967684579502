import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from './../../../interface/dialog.data';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-overview-dialog',
  templateUrl: './dialog-overview-dialog.component.html'
})
export class DialogOverviewDialogComponent {

  mailForm: FormGroup;
  ituForm: FormGroup;
  formX: boolean;

  constructor(public dialogRef: MatDialogRef<DialogOverviewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
                console.log('DATA-Dialog: ', data);
                if (data.val) {
                  this.formX = true;
                  console.log('CON DATO');
                  this.ituForm = new FormGroup( {
                    itu:  new FormControl(data.val, [
                      Validators.required,
                      Validators.minLength(30),
                      Validators.maxLength(30),
                      Validators.pattern('^20[1-9][4-9][0-1][0-9][0-3][0-1][0-9]{17,17}[pP][oO][sS][aA]0|9$') ||
                      Validators.pattern('^20[1-9][4-9][0-1][0-9][0-3][0-1][0-9]{17,17}[pP][oO][sS][aA]9|0$')
                    ])
                  });
                } else {
                  this.formX = false;
                  console.log('SIN DATO');
                  this.mailForm = new FormGroup({
                    mail: new FormControl('', [
                      Validators.required,
                      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$')
                    ])
                  });
                 }
                }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
