import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeneraItuService } from '../../services/genera-itu.service';
import { Person, DataUser } from './../../interface/person.interface';
import { TipoSelectService } from './../../services/tipoSelect.service';
import { SelectTipo, SelectGeneric } from './../../interface/selectTipo.interface';
import swal from 'sweetalert2';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseReinvoice } from 'src/app/interface/response-reinvoice';
import { Router } from '@angular/router';
import { ValidaItuInterface } from './../../interface/array-itu.interface';
import { ResponseString } from './../../interface/response-string';
import { TipoDeUso } from 'src/app/interface/tipo-uso.interface';
import { ClientInfoVO } from 'src/app/interface/client-info-vo.interface';
import { ConsultaRFC } from 'src/app/interface/consultaRFC.interface';
import { AppConfigService } from '../../app-config.service';

@Component({
  selector: 'app-reinvoice-edit-data',
  templateUrl: './reinvoice-edit-data.component.html',
  styleUrls: ['./reinvoice-edit-data.component.css']
})
export class ReinvoiceEditDataComponent implements OnInit {

  color = 'warn';
  colorTexto = '';
  classBtn = '';
  autoRenew1 = new FormControl();
  changeIeps = false;
  nocolony = false;
  autoRenew2 = new FormControl();
  extranjero = false;
  validForeingData = false;
  disabledSelect = false;
  disabledState = false;
  tipouso: TipoDeUso;
  arrTipoUso: SelectTipo[] = [];
  arrRegimenFi: SelectTipo[] = [];

  // Formulario para recabar datos de Facturación

  dataInvoice: DataUser;
  formReInvoice: FormGroup;
  consultaRFC: ConsultaRFC;

  validaItu: ValidaItuInterface;
  ituSendArray: string[] = [];
  ituSeriesArray: string[] = [];

  arrPerson: SelectTipo[] = [];

  estados: SelectTipo[] = [];
  municipios: SelectTipo[] = [];
  colonias: SelectTipo[] = [];
  residenciasFiscales: SelectTipo[] = [];

  persona: string;




  enableForm = false;
  btnAvailable = false;


  
  rfc_l = sessionStorage.getItem('rfc');
  type_p_l = sessionStorage.getItem('type_person');
  uso_c_l = sessionStorage.getItem('uso_cfdi');
  reg_fis = sessionStorage.getItem('reg_fiscal');
  billingId =  Number(sessionStorage.getItem('billingId'));
  xstore = sessionStorage.getItem('xstore');
  auxBillingId =  Number(sessionStorage.getItem('auxBillingId'));
  clientInfoVO: ClientInfoVO;


  
  
  key = '';
  state = '';

  itu = '';

  resetForm = false;

  verFactura = '';


  constructor(private generaItuService: GeneraItuService,
    private fb: FormBuilder,
    private tipoSelect: TipoSelectService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private configService: AppConfigService) {

    this.colorTexto = this.configService.getConfig().colorTexto;
    this.classBtn = this.configService.getConfig().classBtn;
    config.backdrop = 'static';
    config.keyboard = false;
    this.spinnerService.show();
    this.formSinData();
    this.createFormReInvoice();

    this.tipoSelect.getTipoPersona().subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      if (data !== null) {
        this.arrPerson = data.object;
      }
    });

  }

  ngOnInit() {
  }

  createFormReInvoice() {
    console.log('2022-09-12' );
  	const RFC = sessionStorage.getItem('rfc');
    const ITU = sessionStorage.getItem('itu');
    this.itu = ITU;
    this.generaItuService.getClientReinvoice(ITU).subscribe((data: Person) => {
      console.log('DATA: ', data);
      if (!data.status) {
        this.spinnerService.hide();
        swal('Error!', data.msg, 'error');
      } else {
        if (data.object.type !== null) {

          this.dataInvoice = data.object;
          console.log('DATO - ' + this.dataInvoice);


          
          this.getStateZipCode(this.dataInvoice.zipCode);
          this.getMunicipalityZipCode(this.dataInvoice.zipCode);
          this.getSuburbZipCode(this.dataInvoice.zipCode);
          

          //this.getRegimenFiscal();
          this.formConData();
          this.spinnerService.hide();
        }

      }
    }, err => {
      console.error(err);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
  }


  formConData() {
    this.disabledSelect = true;
    this.enableForm = true;
    this.btnAvailable = true;
    console.log('DATO state - ' + this.dataInvoice.state);
    console.log('DATO municipality - ' + this.dataInvoice.municipality);
    console.log('DATO colony - ' + this.dataInvoice.colony);
    console.log('DATO persona - ' + sessionStorage.getItem('type_person'));
    console.log('DATO regFiscal - ' + this.dataInvoice.type);

    this.state = this.dataInvoice.state;
    // if (this.dataInvoice.type === 'F') {
    //   this.tipoSelect.getTipoUsoF().subscribe((data1: SelectGeneric) => {
    //     this.arrTipoUso = data1.object;
    //   });
    // } else if (this.dataInvoice.type === 'M') {
    //   this.tipoSelect.getTipoUsoM().subscribe((data2: SelectGeneric) => {
    //     this.arrTipoUso = data2.object;
    //   });
    // }

    this.tipoSelect.getRegimenFiscalList(this.dataInvoice.type ).subscribe((data1: SelectGeneric) => {
      console.log('DATA: ', data1);
      this.arrRegimenFi = data1.object;
    });

    this.tipouso = {regFiscalId: this.dataInvoice.regimenFiscal , typePerson: this.dataInvoice.type};
    this.tipoSelect.getUsageTypeList(this.tipouso).subscribe((data2: SelectGeneric) => {
      console.log('DATA: ', data2);
      this.arrTipoUso = data2.object;
    });

  this.formReInvoice.get('state').setValue(this.dataInvoice.state);

    if (this.dataInvoice.nacional === 'S' ||
         this.dataInvoice.nacional.trim() === '') {
      this.extranjero = false;
      this.dataInvoice.fiscalResidence = '';
      this.dataInvoice.numRegId = '';
    } else {
      this.extranjero = true;
      this.getResidenciaFisc();
      if (this.dataInvoice.fiscalResidence === '' || this.dataInvoice.fiscalResidence === null) {
        this.key = '';
        this.formReInvoice.get('fiscalResidence').setValue(this.key);
      } else {
        this.key = this.dataInvoice.fiscalResidence.substr(0, 3);
        this.formReInvoice.get('fiscalResidence').setValue(this.key);
      }
    }
    
    console.log('PASANDO');
    this.dataInvoice.iepsRequired = 'N';
    this.formReInvoice = new FormGroup({
      iepsRequired: new FormControl( {value: this.dataInvoice.iepsRequired, disabled: true}),
      rfc: new FormControl( {value: this.dataInvoice.rfc, disabled: true}),
      type: new FormControl( {value: this.dataInvoice.type, disabled: true}),
      name: new FormControl( {value: this.dataInvoice.name, disabled: true}),
      paternalSurname: new FormControl( {value: this.dataInvoice.paternalSurname, disabled: true}),
      maternalSurname: new FormControl( {value: this.dataInvoice.maternalSurname, disabled: true}),
      email: new FormControl( {value: this.dataInvoice.email, disabled: true}),
      street: new FormControl( {value: this.dataInvoice.street, disabled: true}),
      outerNumber: new FormControl( {value: this.dataInvoice.outerNumber, disabled: true}),
      innerNumber: new FormControl( {value: this.dataInvoice.innerNumber, disabled: true}),
      zipCode: new FormControl( {value: this.dataInvoice.zipCode, disabled: true}),
      colony: new FormControl( {value: this.dataInvoice.colony, disabled: true}),
      colonyText: new FormControl( {value: this.dataInvoice.colony, disabled: true}),
      nocolony: new FormControl( {value: '', disabled: true}),
    //  idState: new FormControl( {value: this.dataInvoice.state, disabled: true}),
      state: new FormControl({value: this.dataInvoice.state, disabled: true}),
     // state: new FormControl( {value: this.dataInvoice.state, disabled: true}),
   /* idCity: new FormControl( {value: this.dataInvoice.idCity, disabled: true}),
      city: new FormControl( {value: this.dataInvoice.city, disabled: true}),
      idMunicipality: new FormControl( {value: this.dataInvoice.idMunicipality, disabled: true}), */
      municipality: new FormControl( {value: this.dataInvoice.municipality, disabled: true}),
      useCfdi: new FormControl( {value: this.dataInvoice.useCfdi, disabled: true}),
      regimenFiscal: new FormControl( {value: this.dataInvoice.regimenFiscal, disabled: true}),
      nacional: new FormControl( {value: this.dataInvoice.nacional, disabled: true}),
      numRegId: new FormControl( {value: this.dataInvoice.numRegId, disabled: true}),
      fiscalResidence: new FormControl( {value: this.key, disabled: true}),
      solounico: new FormControl( {value: this.key, disabled: true}),
      idTransaction: new FormControl( {value: this.billingId, disabled: true})
    });


    console.log('PASANDO 1 ' + this.extranjero);
    console.log('DATO formReInvoice - ' + this.formReInvoice.valid);

    //  const inputTech: any = document.querySelector('input[name=rfc]');
    // inputTech.focus();
  }

  formSinData() {

    // console.log("holaaa");
    // this.tipoSelect.getRegimenFiscalList(this.type_p_l ).subscribe((data1: SelectGeneric) => {
    //   this.arrRegimenFi = data1.object;
    // });

    // this.tipouso = {regFiscalId: this.reg_fis , typePerson: this.type_p_l};
    // this.tipoSelect.getUsageTypeList(this.tipouso).subscribe((data2: SelectGeneric) => {
    //   this.arrTipoUso = data2.object;
    // });
  
    this.getResidenciaFisc();
    this.disabledSelect = false;
    this.btnAvailable = false;
    this.formReInvoice = this.fb.group({
      iepsRequired: ['N'],
      rfc: [''],
      type: [''],
      name: [''],
      paternalSurname: [''],
      maternalSurname: [''],
      email: [''],
      street: [''],
      outerNumber: [''],
      innerNumber: [''],
      zipCode: [''],
      colony: [''],
      colonyText: [''],
      nocolony: [''],
    /*  idState: [''],*/
      state: [''],
      municipality: [''],
      useCfdi: [''],
      regimenFiscal: [''],
      nacional: ['S'],
      numRegId: [''],
      fiscalResidence: [''],
      solounico: [''],
      idTransaction: [this.billingId]
    });

    if (this.type_p_l=="M") {
    
      this.formReInvoice.get('paternalSurname').disable();
      this.formReInvoice.get('maternalSurname').disable();
      this.formReInvoice.get('paternalSurname').setValue('');
      this.formReInvoice.get('maternalSurname').setValue('');
    } else {
      this.formReInvoice.get('paternalSurname').enable();
      this.formReInvoice.get('maternalSurname').enable();
    }
  }

  getRegimenFiscal(tPersona: FormGroup) {
    this.type_p_l=tPersona.controls['type'].value;

    this.tipoSelect.getRegimenFiscalList(this.type_p_l).subscribe((data1: SelectGeneric) => {
      console.log('DATA: ', data1);
      this.arrRegimenFi = data1.object;
    });

    if (this.type_p_l=="M") {
    
      this.formReInvoice.get('paternalSurname').disable();
      this.formReInvoice.get('maternalSurname').disable();
      this.formReInvoice.get('paternalSurname').setValue('');
      this.formReInvoice.get('maternalSurname').setValue('');
    } else {
      this.formReInvoice.get('paternalSurname').enable();
      this.formReInvoice.get('maternalSurname').enable();
    }
  }

  /*getTipoUso(tPersona: FormGroup) {
    console.log('TIPO PERSONA === ' + tPersona.controls['type'].value);
    if (tPersona.controls['type'].value === 'F') {
      this.validateRfcFisica();
      this.tipoSelect.getTipoUsoF().subscribe((data1: SelectGeneric) => {
        this.arrTipoUso = data1.object;
      });
    } else {
      this.validateRfcMoral();
      this.tipoSelect.getTipoUsoM().subscribe((data2: SelectGeneric) => {
        this.arrTipoUso = data2.object;
      });
    }
  }*/
  //Se modifica getTipoUso para recibir regFiscalId y typePerson
  getTipoUso(tRegimen: FormGroup) {
    console.log('entro aqui ' , tRegimen.controls);
    
    console.log('entro  ' , tRegimen.controls['regimenFiscal'].value);
    this.tipouso = {regFiscalId: tRegimen.controls['regimenFiscal'].value , typePerson: tRegimen.controls['type'].value };
    this.tipoSelect.getUsageTypeList(this.tipouso).subscribe((data2: SelectGeneric) => {
      console.log('DATA: ', data2);
      this.arrTipoUso = data2.object;
    });
  }



  // Activa campos del formulario para poder editarlos
  enableField() {
    this.formReInvoice.enable();

    if (this.type_p_l=="M") {
    
      this.formReInvoice.get('paternalSurname').disable();
      this.formReInvoice.get('maternalSurname').disable();
      this.formReInvoice.get('paternalSurname').setValue('');
      this.formReInvoice.get('maternalSurname').setValue('');
    } else {
      this.formReInvoice.get('paternalSurname').enable();
      this.formReInvoice.get('maternalSurname').enable();
    }
  }
  




  getMunicipality(id, value) {
    this.formReInvoice.get('state').setValue(value);
    if(id=="0"){
     
      console.log("value ", value);
      id=value;
    }
    this.tipoSelect.getMunicipality(id).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.municipios = data.object;
    });
  }





  getSuburb(id, value) {
    this.formReInvoice.get('municipality').setValue(value);
    if(id=="0"){
     
      console.log("getSuburb value ", value);
      id=value;
    }

    this.tipoSelect.getSuburb(id).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.colonias = data.object;
    });
  }
  private validateRfcFisica() {
    const patt = new RegExp('^[A-Za-z]{4}[0-9]{2}[01][0-9][0-3][0-9][!-}ñÑ]{3}$');
    console.log(patt.test(this.formReInvoice.controls['rfc'].value));
    if (!patt.test(this.formReInvoice.controls['rfc'].value)) {
      this.sendMessagePattern();
      return false;
    } else {
      this.sendMessagePatternOK();
      return true;
    }
  }

  private validateRfcMoral() {
    const patt = new RegExp('^[A-Za-z]{3}[0-9]{2}[01][0-9][0-3][0-9][!-}ñÑ]{3}$');
    console.log(patt.test(this.formReInvoice.controls['rfc'].value));
    if (!patt.test(this.formReInvoice.controls['rfc'].value)) {
      this.sendMessagePattern();
      return false;
    } else {
      this.sendMessagePatternOK();
      return true;
    }
  }

  private sendMessagePattern() {
    const inputTech: any = document.querySelector('input[name=rfc]');
    inputTech.focus();
    this.formReInvoice.controls['rfc'].setErrors({ 'touched': true });
    this.formReInvoice.controls['rfc'].setErrors({ 'pattern': true });
    const inputTech3: any = document.querySelector('input[name=rfc]');
    inputTech3.focus();
  }

  private sendMessagePatternOK() {
    this.formReInvoice.controls['rfc'].setErrors(null);
  }



  getStateZipCode(zipCode) {
    this.tipoSelect.getStateZipCode(zipCode).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.estados = data.object;
    });
  }

  getMunicipalityZipCode(zipCode) {
    this.tipoSelect.getMunicipality(zipCode).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.municipios = data.object;
    });
  }

  getSuburbZipCode(zipCode) {
    this.tipoSelect.getSuburb(zipCode).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.colonias = data.object;
    });
  }

  getResidenciaFisc() {
    this.tipoSelect.getResidenciasFiscales().subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      if (!data.status) {
        swal('Error!', data.msg, 'error');
      } else {
        this.residenciasFiscales = data.object;
      }
    });
  }

  getList() {
    const code = this.formReInvoice.get('zipCode').value;
    // console.log(code.length);
    if (code.length === 5) {
      console.log(' YA ESTA ');
      this.getStateZipCode(code);
      this.getMunicipalityZipCode(code);
      this.getSuburbZipCode(code);

    }
  }

  onChangeIEPS() {
    if (this.changeIeps) {
      this.changeIeps = false;
      this.formReInvoice.get('iepsRequired').setValue('N');
    } else {
      this.changeIeps = true;
      this.formReInvoice.get('iepsRequired').setValue('S');

    }
  }

  onChangeForeign() {
    if (this.extranjero) {
      this.extranjero = false;
      this.formReInvoice.get('nacional').setValue('S');
    } else {
      this.extranjero = true;
      this.formReInvoice.get('nacional').setValue('N');
    }
  }
  private validateRFC(): boolean {
    if (this.formReInvoice.value.type === 'F') {
      return this.validateRfcFisica();
    } else if (this.formReInvoice.value.type === 'M') {
      return this.validateRfcMoral();
    }
  }

  //Habilita colonoa en texto

  completeItem(){

    if (this.nocolony) {
      this.nocolony = false;
      this.formReInvoice.get('colony').enable();
      this.formReInvoice.get('colonyText').disable();
    } else {
      this.nocolony = true;
      this.formReInvoice.get('colony').disable();
      this.formReInvoice.get('colonyText').enable();

    }
  
  }

  reInvoice(formReInvoice: FormGroup, content) {
    
      this.spinnerService.show();
      console.log('Refacturar');
      
      this.dataInvoice = formReInvoice.value;
      if (this.nocolony) {
        this.dataInvoice.colony=  this.formReInvoice.get('colonyText').value;
        console.log('colonyText: ', this.dataInvoice.colony);
  
      } else {
  
        this.dataInvoice.colony=  this.formReInvoice.get('colony').value;
        console.log('colony: ', this.dataInvoice.colony);
        
        
      }
      this.dataInvoice.email = this.dataInvoice.email.trim().toLowerCase();
      this.dataInvoice.xstore = this.itu;
      //Arma objeto para validar RFC
      if (this.dataInvoice.type === 'F') {
                this.consultaRFC = {businessName:this.dataInvoice.name+" "+this.dataInvoice.paternalSurname+" "+this.dataInvoice.maternalSurname, postalCode:this.dataInvoice.zipCode, regimenFiscal:this.dataInvoice.regimenFiscal, rfc:this.dataInvoice.rfc};
                this.consultaRFC.businessName = this.consultaRFC.businessName.trim();
      }else{
        this.consultaRFC = {businessName:this.dataInvoice.name, postalCode:this.dataInvoice.zipCode, regimenFiscal:this.dataInvoice.regimenFiscal, rfc:this.dataInvoice.rfc};
        this.consultaRFC.businessName = this.consultaRFC.businessName.trim();
      }
      if(this.dataInvoice.rfc=='XAXX010101000'){
        this.tipoSelect.sendReInvoice(this.dataInvoice).subscribe((data: ResponseString) => {
          console.log('DATA: ', data);

          if (data.status) {
            this.spinnerService.hide();
            if(data.object == 'OK'){
              this.modalService.open(content);
              //swal('', 'Refacturación Realizada Exitosamente ' + this.itu, 'success');
              //this.router.navigate(['/home']);
            }else{
              this.spinnerService.hide();
              swal('Error!', data.object, 'error');
            }
          } else {
            this.spinnerService.hide();
            swal('', data.msg, 'error');
          }
        }, err => {
          console.error(err);
          this.spinnerService.hide();
          swal('Error!', 'Error al conectar con el Servidor', 'error');
        });

      }else{
        this.tipoSelect.validaRFC(this.consultaRFC).subscribe((data: ResponseString) => {
          console.log('DATA: ', data);
          //this.spinnerService.hide();
          if (data.status) {
            this.tipoSelect.sendReInvoice(this.dataInvoice).subscribe((data: ResponseString) => {
              console.log('DATA: ', data);
              
              if (data.status) {
                this.spinnerService.hide();
                if(data.object == 'OK'){
                  this.modalService.open(content);
                  //swal('', 'Refacturación Realizada Exitosamente ' + this.itu, 'success');
                  //this.router.navigate(['/home']);
                }else{
                  this.spinnerService.hide();
                  swal('Error!', data.object, 'error');
                }
              } else {
                this.spinnerService.hide();
                swal('', data.msg, 'error');
              }
            }, err => {
              console.error(err);
              this.spinnerService.hide();
              swal('Error!', 'Error al conectar con el Servidor', 'error');
            });
          } else {
            this.spinnerService.hide();
            swal('', data.msg, 'error');
            
          }
         
        }, err => {
          console.error(err);
          this.spinnerService.hide();
          swal('Error!', 'Error al conectar con el Servidor', 'error');
        });

      }
      
      


      
    
  }

  verFact() {
    this.modalService.dismissAll();
    this.router.navigate(['/home']);
  }

  cancel() {
    this.spinnerService.show();
    this.billingId = Number(sessionStorage.getItem('billingId'));
    this.validaItu = { billingId: this.billingId, itu: this.itu, amount: 0, listItus: this.ituSendArray, xstore: '', series: this.ituSeriesArray, totalItus: 1, auxBillingId: 0 };
    this.generaItuService.cancelReinvoice(this.validaItu).subscribe((data: ResponseString) => {
      console.log('DATA: ', data);
      if (data.status) {
        if(data.object == 'OK'){
          swal('', 'Refacturación CANCELADA itu: ' + this.itu, 'success');
          this.router.navigate(['/home']);
          this.spinnerService.hide();
        }else{
          swal('Error!', data.object, 'error');
        }
      } else {
        this.spinnerService.hide();
        swal('Error!', 'Error al validar el ITU, vuelve a intentarlo', 'error');
      }
    }, err => {
      console.error(err);
      this.spinnerService.hide();
      swal('Error!', 'Error al conectar con el Servidor', 'error');
    });
    console.log('Cancel');
  }
}
