import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-politicas',
  templateUrl: './politicasHuawei.component.html',
  styleUrls: ['./politicasHuawei.component.css']
})
export class PoliticasComponent implements OnInit {

  urlGenera: SafeResourceUrl;

  constructor(private congifServer: AppConfigService, sanitizer: DomSanitizer) {
    this.urlGenera = sanitizer.bypassSecurityTrustResourceUrl(this.congifServer.getConfig().politicas);
  }

  ngOnInit() {
  }

}
