import { Component, OnInit } from '@angular/core';

import { environment } from './../../../environments/environment';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  fecha = new Date();
  businessName = '';
  linkPage = '';
  avisoPrivacidad = '';
  politicas='';

  constructor( private configService: AppConfigService) { 
    this.businessName = this.configService.getConfig().footerText;
    this.linkPage = this.configService.getConfig().footerLink;
    this.avisoPrivacidad = this.configService.getConfig().avisoPrivacidad;
    this.politicas =this.configService.getConfig().politicas;
  }

  ngOnInit() {
  }

}
