import {Component, ViewChild} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource, MatSidenav} from '@angular/material';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../app-config.service';
/**
 * Food data with nested structure.
 * Each node has a name and an optiona list of children.
 */
interface FoodNode {
  name: string;
  icon: string;
  url?: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Inicio',
    icon: 'home',
    url: '/'    
  },
  {
    name: 'Generar factura',
    icon: 'assignment',
    url: '/generaF'    
  },
  /*{
    name: 'Refacturación',
    icon: 'assignment',
    url: '/refacturacion'    
  },*/
  {
    name: 'Mis facturas',
    icon: 'image_search',
    url: '/cItuF'
  },
  {
    name: 'Dudas frecuentes',
    icon: 'description',
    children: [
      {name: 'Tipo de ITU´s', icon: 'chrome_reader_mode', url: '/itusTypes'},
      {name: 'Genera factura', icon: 'chrome_reader_mode', url: '/guideInvoice'},
      {name: 'Refacturación', icon: 'chrome_reader_mode', url: '/guideReinvoice'},
      {name: 'Consulta e impresión de F.E', icon: 'chrome_reader_mode', url: '/guideConsult'},
      {name: 'F.A.Q.', icon: 'chrome_reader_mode', url: '/faq'}, 
    ]    
  }
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  icon: string;
  level: number;
}

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})




export class MainNavComponent {
  panelOpenState = false;

  // menu: MenuInterface[] = [];
  menu: any[] = [];
  @ViewChild('drawer') drawer!: MatSidenav;
  msg = 'Imposible conectar con el servidor';

  logotype = '';
  logoHeader = '';
  colorTexto = '';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      icon: node.icon,
      url: node.url,
      level: level,
    };
  }

// tslint:disable-next-line: member-ordering
  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

// tslint:disable-next-line: member-ordering
  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.children);

// tslint:disable-next-line: member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private router: Router,
              private breakpointObserver: BreakpointObserver, private configService: AppConfigService) {
    
    this.logotype = this.configService.getConfig().logotype;
    this.logoHeader = this.configService.getConfig().logoHeader;
    this.colorTexto = this.configService.getConfig().colorTexto;
    if(this.configService.getConfig().internal) {
      TREE_DATA[3].children.push({name: 'R.F.C.', icon: 'looks_two', url: '/cRfcF'});
    }
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  seeGuide( param ) {
    console.log('Ver guía!!!');
  }

  closeMenu(drawer: MatSidenav): void {
    if (window.innerWidth < 768) {
      drawer.close();
    }
  }



}
