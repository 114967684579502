import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConsultInterface } from './../interface/consult.interface';

import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultService {

  private URL = '';

  headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.URL = this.configService.getConfig().urlServiceConsulta;
  }

  consultByITU(obj: ConsultInterface) {
    obj.company = this.configService.getConfig().companyName;
    console.log('new object in request Consult /consultByITU -> ', obj);
    return this.http.post(`${this.URL}/consultByITU`, obj);
  }

  consultByRFC(obj: ConsultInterface) {
    obj.company = this.configService.getConfig().companyName;
    console.log('new object in request Consult /consultByRFC is', obj);
    return this.http.post(`${this.URL}/consultByRFC`, obj);
  }

  getXML(voucher) {
    console.log('new object in request Consult /getXml is', voucher);
    return this.http.post(`${this.URL}/getXml`, voucher, {headers: this.headers});
  }

  getPDF(itu) {
    console.log('new object in request Consult /getPdf is', itu);
    return this.http.post(`${this.URL}/getPdf`, itu, {headers: this.headers});
  }

  sendEmail(obj: ConsultInterface) {
    obj.company = this.configService.getConfig().companyName;
    console.log('new object in request Consult /sendEmail -> ', obj);
    return this.http.post(`${this.URL}/sendEmail`, obj);
  }
}
